import React, { useEffect, useRef, useState } from "react";
// import axios from "axios";
import AOS from "aos";
import "aos/dist/aos.css";
import Icon from "@mdi/react";
import { mdiWhatsapp, mdiInstagram, mdiFacebook, mdiTwitter } from "@mdi/js";
import emailjs from "@emailjs/browser";
import contact from "./images/contact.jpg";
import LOG3 from "./images/LOG3.jpg";
import LOG4 from "./images/LOG4.jpg";
import icon1 from "./images/icon1-b.jpg";
import icon2 from "./images/icon2-b.jpg";
import icon3 from "./images/icon3-b.jpg";
import icon4 from "./images/icon4-b.jpg";
import icon5 from "./images/icon5-b.jpg";
import icon6 from "./images/icon6-b.jpg";
import idea from "./images/idea.png";
import parkidle from "./images/park-idle.jpg";
import truckpark from "./images/truck-park.jpg";
import "./styles/main.css";
import "./styles/style.css";

function App() {
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const form = useRef();

  const handleSend = async (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_85d93ja",
        "template_k6rjwjh",
        form.current,
        "Zu0cNqqzb026JNaR6"
      )
      .then(
        (result) => {
          console.log(result);
          toggleToast("success-toast");

          setTimeout(() => {
            toggleToast("success-toast");
            e.target.reset();
          }, 5000);
        },
        (error) => {
          toggleToast("failure-toast");
          setTimeout(() => {
            toggleToast("failure-toast");
          }, 5000);
          console.log(error.text);
        }
      );
    // axios
    //   .post("/contact", {
    //     firstname,
    //     lastname,
    //     email,
    //     message,
    //   })
    //   .then((res) => console.log(res))
    //   .catch((error) => console.error(error));
  };
  const toggleToast = (toastname) => {
    const toast = document.getElementById(toastname);
    toast.classList.toggle("hidden");
    toast.classList.toggle("flex");
  };

  useEffect(() => {
    const navLinks = document.querySelectorAll(".nav-link");
    const onNavLinkClick = () => {
      navLinks.forEach((element) => {
        element.addEventListener("click", (event) => {
          event.preventDefault();
          // [].forEach.call(navLinks, function (el) {
          //   el.classList.remove("active-link");
          //   el.classList.add("text-slate-600");
          // });
          // element.classList.add("active-link");
          // element.classList.remove("text-slate-600");
          const div = element.getAttribute("href");
          const view = document.querySelector(div);
          view.scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "start",
          });
        });
      });
    };
    AOS.init({
      offset: 0,
      duration: 800,
      easing: "ease-in-quad",
      delay: 100,
    });
    AOS.refresh();
    onNavLinkClick();
  }, []);
  useEffect(() => {
    if ("serviceWorker" in navigator && navigator.onLine) {
      navigator.serviceWorker.register("/service-worker.js").then(
        (reg) => {
          console.log("Service worker registered -->", reg);
        },
        (err) => {
          console.error("Service worker not registered -->", err);
        }
      );
    }
    let beforeInstallEvent;

    // Reference the install button from DOM.
    const installButton = document.getElementById("install");

    // Watch for the beforeinstallevent and defer it.
    window.addEventListener("beforeinstallprompt", (event) => {
      event.preventDefault();
      beforeInstallEvent = event;
      installButton.style.display = "block";
    });

    // Prompt for Installation when install button is clicked.
    installButton.addEventListener("click", () => {
      beforeInstallEvent.prompt().then((choice) => {
        // Hide the install button as its purpose is over.
        if (choice.outcome === "accepted") {
          installButton.style.display = "none";
        }
      });
    });
  }, []);
  return (
    <div id="content" className="relative">
      <div
        id="failure-toast"
        className="bg-slate-100 z-[1999] fixed hidden right-5 top-5 border-l-4 border-red-500 p-4 py-6 rounded shadow-lg items-center justify-between mb-6"
        role="alert"
      >
        <span className="fa-stack fa-2x sm:mr-2 mb-3">
          <i className="fas fa-circle text-red-700 fa-stack-2x"></i>
          <i className="fas fa-times fa-stack-1x text-white"></i>
        </span>
        <div className="sm:text-left text-center sm:mb-0 mb-3 w-128">
          <p className="font-bold mb-1 text-lg">Failure.</p>
          <p className="text-slate-500 inline-block">
            Your message failed to send. Please try again
          </p>
        </div>
        <i
          onClick={() => toggleToast("failure-toast")}
          className="fas fa-times mx-4 fa-2x text-slate-700"
        ></i>
      </div>
      <div
        className="bg-slate-100 hidden z-[1999] fixed right-5 top-5 border-l-4 border-green-500 p-4 py-6 rounded shadow-lg items-center justify-between mb-6"
        role="alert"
        id="success-toast"
      >
        <span className="fa-stack fa-2x sm:mr-2 mb-3">
          <i className="fas fa-circle text-green-700 fa-stack-2x"></i>
          <i className="fas fa-check fa-stack-1x text-white"></i>
        </span>
        <div className="sm:text-left text-center sm:mb-0 mb-3 w-128">
          <p className="font-bold mb-1 text-lg">Success.</p>
          <p className="text-slate-500 inline-block">
            Your message was sent successfully.
          </p>
        </div>
        <i
          onClick={() => toggleToast("success-toast")}
          className="fas fa-times mx-4 fa-2x text-slate-700"
        ></i>
      </div>
      <header className="w-full px-8 text-slate-700 bg-white">
        <div className="container hidden md:flex flex-col flex-wrap items-center justify-between py-5 mx-auto md:flex-row max-w-7xl">
          <div className="relative flex flex-col md:flex-row">
            <a
              href="#_"
              className="flex items-center mb-5 font-medium lg:w-auto lg:items-center lg:justify-center md:mb-0"
            >
              <img className="h-12 w-36" src={LOG4} alt="logo" />
            </a>
            <nav className="flex flex-wrap items-center mb-5 text-base md:mb-0 md:pl-8 md:ml-8 md:border-l md:border-slate-200">
              <a
                href="#home"
                className="nav-link mr-5 font-medium leading-6 text-slate-600 hover:text-blue-600"
              >
                Home
              </a>
              <a
                href="#services"
                className="nav-link mr-5 font-medium leading-6 text-slate-600 hover:text-blue-600"
              >
                Services
              </a>
              <a
                href="#about"
                className="nav-link mr-5 font-medium leading-6 text-slate-600 hover:text-blue-600"
              >
                About
              </a>
              <a
                href="#plans"
                className="nav-link mr-5 font-medium leading-6 text-slate-600 hover:text-blue-600"
              >
                Plans
              </a>
              <a
                href="#contact"
                className="nav-link mr-5 font-medium leading-6 text-slate-600 hover:text-blue-600"
              >
                Contact US
              </a>
              <div
                id="install"
                className="mr-5 cursor-pointer hidden font-medium leading-6 rounded bg-blue-600 hover:bg-blue-700 py-2 px-4 text-white"
              >
                Download App
              </div>
            </nav>
          </div>

          <div className="inline-flex items-center ml-5 space-x-6 lg:justify-end md:pt-3 lg:pt-0">
            <a
              href="https://app.c2cloads.com/login"
              className="text-base font-medium leading-6 text-slate-600 whitespace-no-wrap transition duration-150 ease-in-out hover:text-slate-900"
            >
              Log In
            </a>
            <a
              href="https://api.whatsapp.com/send?phone=263713664658"
              className="inline-flex items-center justify-center px-4 py-2 text-base font-medium leading-6 text-white whitespace-no-wrap bg-green-400 border border-transparent rounded-full shadow-sm hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-600"
            >
              <Icon
                className=" mr-1"
                size={1}
                path={mdiWhatsapp}
                rotate={90}
                horizontal
                vertical
              />
              Whatsapp
            </a>
          </div>
        </div>
        <div className="text-slate-900 w-full bg-white md:hidden flex flex-wrap items-center py-5">
          <div className="flex-1 justify-between items-center">
            <a
              href="#_"
              className="flex items-center mb-5 font-medium lg:w-auto lg:items-center lg:justify-center md:mb-0"
            >
              <img className="h-12 w-36" src={LOG4} alt="logo" />
            </a>
          </div>

          <label htmlFor="menu-toggle" className="pointer-cursor">
            <svg
              className="fill-current text-slate-900"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
            >
              <title>menu</title>
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
            </svg>
          </label>
          <input className="hidden" type="checkbox" id="menu-toggle" />

          <div
            className="hidden w-full border-t mt-3 pt-3 border-slate-700"
            id="menu"
          >
            <nav>
              <ul className="items-center justify-between text-base pt-4 md:pt-0">
                <li>
                  <a
                    href="#home"
                    className="nav-link mr-5 md:p-4 py-3 px-0 block font-medium leading-6 text-slate-600 hover:text-blue-600"
                  >
                    Home
                  </a>
                </li>
                <li>
                  <a
                    href="#services"
                    className="nav-link mr-5 md:p-4 py-3 px-0 block font-medium leading-6 text-slate-600 hover:text-blue-600"
                  >
                    Services
                  </a>
                </li>
                <li>
                  <a
                    href="#about"
                    className="nav-link mr-5 md:p-4 py-3 px-0 block font-medium leading-6 text-slate-600 hover:text-blue-600"
                  >
                    About
                  </a>
                </li>
                <li>
                  <a
                    href="#plans"
                    className="nav-link mr-5 md:p-4 py-3 px-0 block font-medium leading-6 text-slate-600 hover:text-blue-600"
                  >
                    Plans
                  </a>
                </li>
                <li>
                  <a
                    href="#contact"
                    className="nav-link mr-5 md:p-4 py-3 px-0 block font-medium leading-6 text-slate-600 hover:text-blue-600"
                  >
                    Contact Us
                  </a>
                </li>
                <li className="border-t mt-3 pt-3 border-slate-700">
                  <a
                    href="https://app.c2cloads.com/login"
                    className="flex items-center px-6 py-3 text-slate-600 bg-slate-100 rounded-md hover:bg-slate-200 hover:text-slate-600"
                  >
                    Log in
                  </a>
                </li>
                <li className="mt-2 border-b border-slate-700">
                  <a
                    href="https://api.whatsapp.com/send?phone=263713664658"
                    className="flex items-center w-full px-6 py-3 mb-3 text-lg text-white bg-green-400 rounded-md sm:mb-0 hover:bg-green-600 sm:w-auto"
                  >
                    Whatsapp
                    <Icon
                      className=" ml-1"
                      size={1}
                      path={mdiWhatsapp}
                      rotate={90}
                      horizontal
                      vertical
                    />
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </header>

      <section id="home" className="px-2 py-8 bg-white md:px-0 lg:py-32">
        <div className="container items-center max-w-6xl px-16 mx-auto xl:px-5">
          <div className="flex flex-wrap items-center sm:-mx-3">
            <div className="w-full lg:w-1/2 lg:px-3">
              <div className="w-full pb-6 space-y-6 sm:max-w-md md:max-w-lg md:space-y-4 lg:space-y-8 xl:space-y-9 sm:pr-5 lg:pr-0 lg:pb-0">
                <h1
                  className={`text-3xl font-extrabold tracking-tight text-slate-900 md:text-3xl lg:text-4xl xl:text-5xl poppins `}
                >
                  Find the best loads and{" "}
                  <span className="text-blue-600 mr-2">
                    Earthmoving jobs anytime, anywhere.
                  </span>
                </h1>
                <p
                  data-aos="fade-right"
                  className="mx-auto text-sm text-slate-500 sm:max-w-md lg:text-base md:max-w-3xl"
                >
                  Cape2Cairo is a growing platform which connects trucks with
                  loads and earth moving equipment with jobs, within borders and
                  across Africa. C2C consolidates loads and jobs across various
                  platforms making them more accessible to you through web and
                  mobile platforms with real-time updates.
                </p>
                <div className="relative flex flex-col sm:flex-row sm:space-x-4">
                  <a
                    href="https://app.c2cloads.com/login"
                    className="flex items-center w-full px-6 py-3 mb-3 text-lg text-white bg-blue-600 rounded-md sm:mb-0 hover:bg-blue-700 sm:w-auto"
                  >
                    Try It Free
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-5 h-5 ml-1"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <line x1="5" y1="12" x2="19" y2="12"></line>
                      <polyline points="12 5 19 12 12 19"></polyline>
                    </svg>
                  </a>
                  <a
                    href="#_"
                    className="flex items-center px-6 py-3 text-slate-500 bg-slate-100 rounded-md hover:bg-slate-200 hover:text-slate-600"
                  >
                    Learn More
                  </a>
                </div>
              </div>
            </div>
            <div className="w-full lg:w-1/2">
              <div
                data-aos="zoom-in-up"
                className="w-full h-auto overflow-hidden rounded-md shadow-xl sm:rounded-xl"
              >
                <img alt="hero" src={parkidle} />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="services" className="px-2 py-8 text-slate-700 body-font">
        <div className="container items-center lg:max-w-4xl xl:max-w-5xl px-8 mx-auto xl:px-5">
          <div className="flex flex-col items-center leading-7 text-center text-slate-900">
            <h2 className="box-border m-0 text-3xl font-semibold leading-tight tracking-tight text-black border-solid md:text-4xl">
              We are here to help you
            </h2>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            <div data-aos="zoom-in" data-aos-duration="2000" className="p-4">
              <div className="p-6 text-center rounded-lg">
                <div className="w-32 h-32 inline-flex items-center justify-center rounded-full bg-blue-100 text-blue-500 mb-4">
                  <img src={icon6} alt="find loads" className="w-20 h-20" />
                </div>
                <h2 className="text-lg text-slate-900 font-medium title-font mb-2">
                  Find Loads
                </h2>
                <p className="leading-relaxed text-base">
                  Find truckloads locally and across Africa. Secure backloads.
                </p>
              </div>
            </div>
            <div data-aos="zoom-in" data-aos-duration="1000" className="p-4">
              <div className="p-6 text-center rounded-lg">
                <div className="w-32 h-32 inline-flex items-center justify-center rounded-full bg-blue-100 text-blue-500 mb-4">
                  <img src={icon5} alt="find trucks" className="w-20 h-20" />
                </div>
                <h2 className="text-lg text-slate-900 font-medium title-font mb-2">
                  Find Trucks
                </h2>
                <p className="leading-relaxed text-base">
                  Choose the right truck for your load.
                </p>
              </div>
            </div>
            <div data-aos="zoom-in" data-aos-duration="1500" className="p-4">
              <div className="p-6 text-center rounded-lg">
                <div className="w-32 h-32 inline-flex items-center justify-center rounded-full bg-blue-100 text-blue-500 mb-4">
                  <img
                    src={icon2}
                    alt="Find Earthmoving Machinery"
                    className="w-20 h-20"
                  />
                </div>
                <h2 className="text-lg text-slate-900 font-medium title-font mb-2">
                  Find Earthmoving Machinery
                </h2>
                <p className="leading-relaxed text-base">
                  Choose machinery from our vast number of operators.
                </p>
              </div>
            </div>
            <div data-aos="zoom-in" data-aos-duration="500" className="p-4">
              <div className="p-6 text-center rounded-lg">
                <div className="w-32 h-32 inline-flex items-center justify-center rounded-full bg-blue-100 text-blue-500 mb-4">
                  <img src={icon4} alt="Find Jobs" className="w-20 h-20" />
                </div>
                <h2 className="text-lg text-slate-900 font-medium title-font mb-2">
                  Find Jobs
                </h2>
                <p className="leading-relaxed text-base">
                  Choose jobs for your machine and schedule.
                </p>
              </div>
            </div>
            <div data-aos="zoom-in" data-aos-duration="2000" className="p-4">
              <div className="p-6 text-center rounded-lg">
                <div className="w-32 h-32 inline-flex items-center justify-center rounded-full bg-blue-100 text-blue-500 mb-4">
                  <img src={icon1} alt="Post Loads" className="w-20 h-20" />
                </div>
                <h2 className="text-lg text-slate-900 font-medium title-font mb-2">
                  Post Loads
                </h2>
                <p className="leading-relaxed text-base">
                  Get several offers from truckers for your loads.
                </p>
              </div>
            </div>
            <div data-aos="zoom-in" data-aos-duration="1500" className="p-4">
              <div className="p-6 text-center rounded-lg">
                <div className="w-32 h-32 inline-flex items-center justify-center rounded-full bg-blue-100 text-blue-500 mb-4">
                  <img src={icon3} alt="  Post Jobs" className="w-20 h-20" />
                </div>
                <h2 className="text-lg text-slate-900 font-medium title-font mb-2">
                  Post Jobs
                </h2>
                <p className="leading-relaxed text-base">
                  Get several offers on your job from machine operators.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        id="about"
        className="w-full bg-white pt-7 pb-7 md:pt-20 md:pb-24"
      >
        <div className="box-border lg:max-w-4xl xl:max-w-5xl flex flex-col items-center content-center px-8 mx-auto leading-6 text-black border-0 border-slate-300 border-solid md:flex-row lg:px-16">
          <div
            data-aos="fade-right"
            className="box-border relative w-full max-w-md px-4 mt-5 mb-4 -ml-5 text-center bg-no-repeat bg-contain border-solid md:ml-0 md:mt-0 md:max-w-none lg:mb-0 md:w-1/2 xl:pl-10"
          >
            <img
              src={idea}
              alt="idea"
              className="p-2 pl-6 pr-5 xl:pl-16 xl:pr-20"
            />
          </div>

          <div
            data-aos="fade-left"
            className="box-border order-first w-full text-black border-solid md:w-1/2 md:pl-10 md:order-none"
          >
            <h2 className="m-0 text-lg font-semibold leading-tight border-0 border-slate-300 lg:text-2xl md:text-xl">
              How it Works
            </h2>
            <p className="pt-4 pb-8 m-0 leading-7 text-slate-700 border-0 border-slate-300 sm:pr-12 xl:pr-32 text-base">
              Save time and money with our revolutionary services. We are the
              leaders in the industry.
            </p>
            <ul className="p-0 m-0 leading-6 border-0 border-slate-300">
              <li className="box-border relative py-1 pl-0 text-left text-slate-500 border-solid">
                <span className="inline-flex items-center justify-center w-6 h-6 mr-2 text-white bg-blue-300 rounded-full">
                  <span className="text-sm font-bold">✓</span>
                </span>
                Load/Job owners register for free.
              </li>
              <li className="box-border relative py-1 pl-0 text-left text-slate-500 border-solid">
                <span className="inline-flex items-center justify-center w-6 h-6 mr-2 text-white bg-blue-300 rounded-full">
                  <span className="text-sm font-bold">✓</span>
                </span>
                Post a Load/Job.
              </li>
              <li className="box-border relative py-1 pl-0 text-left text-slate-500 border-solid">
                <span className="inline-flex items-center justify-center w-6 h-6 mr-2 text-white bg-blue-300 rounded-full">
                  <span className="text-sm font-bold">✓</span>
                </span>
                Truckers are alerted of new job/load.
              </li>
              <li className="box-border relative py-1 pl-0 text-left text-slate-500 border-solid">
                <span className="inline-flex items-center justify-center w-6 h-6 mr-2 text-white bg-blue-300 rounded-full">
                  <span className="text-sm font-bold">✓</span>
                </span>
                Load/Job owners receive bids.
              </li>
              <li className="box-border relative py-1 pl-0 text-left text-slate-500 border-solid">
                <span className="inline-flex items-center justify-center w-6 h-6 mr-2 text-white bg-blue-300 rounded-full">
                  <span className="text-sm font-bold">✓</span>
                </span>
                Load/Job owners accept bids.
              </li>
              <li className="box-border relative py-1 pl-0 text-left text-slate-500 border-solid">
                <span className="inline-flex items-center justify-center w-6 h-6 mr-2 text-white bg-blue-300 rounded-full">
                  <span className="text-sm font-bold">✓</span>
                </span>
                Trucker/operator fulfils load/job.
              </li>
              <li className="box-border relative py-1 pl-0 text-left text-slate-500 border-solid">
                <span className="inline-flex items-center justify-center w-6 h-6 mr-2 text-white bg-blue-300 rounded-full">
                  <span className="text-sm font-bold">✓</span>
                </span>
                Provide reviews.
              </li>
            </ul>
          </div>
        </div>
        <div className="box-border flex flex-col items-center content-center px-8 mx-auto mt-2 leading-6 text-black border-0 border-slate-300 border-solid md:mt-20 xl:mt-0 md:flex-row max-w-7xl lg:px-16">
          <div
            data-aos="fade-right"
            className="box-border w-full text-black border-solid md:w-1/2 md:pl-6 lg:pl-20 xl:pl-32"
          >
            <h2 className="m-0 textlg font-semibold leading-tight border-0 border-slate-300 lg:text-2xl md:text-xl">
              Don't let your trucks park idle!
            </h2>
            <p className="pt-8 m-0 leading-7 text-slate-700 border-0 border-slate-300 sm:pr-10 lg:text-base">
              Stay connected to clients in need of your services truck
            </p>
            <p className="pt-4 pb-8 m-0 leading-7 text-slate-500 border-0 border-slate-300 sm:pr-10 text-sm">
              Register as a trucker on our mobile and web apps and we will help
              you find loads to suit your truck
            </p>
            <ul className="p-0 m-0 leading-6 border-0 border-slate-300">
              <li className="box-border relative py-1 pl-0 text-left text-slate-500 border-solid">
                <span className="inline-flex items-center justify-center w-6 h-6 mr-2 text-white bg-blue-300 rounded-full">
                  <span className="text-sm font-bold">✓</span>
                </span>
                Register using your phone number and email
              </li>
              <li className="box-border relative py-1 pl-0 text-left text-slate-500 border-solid">
                <span className="inline-flex items-center justify-center w-6 h-6 mr-2 text-white bg-blue-300 rounded-full">
                  <span className="text-sm font-bold">✓</span>
                </span>
                Distance search for the right loads
              </li>
              <li className="box-border relative py-1 pl-0 text-left text-slate-500 border-solid">
                <span className="inline-flex items-center justify-center w-6 h-6 mr-2 text-white bg-blue-300 rounded-full">
                  <span className="text-sm font-bold">✓</span>
                </span>
                Find backloads
              </li>
              <li className="box-border relative py-1 pl-0 text-left text-slate-500 border-solid">
                <span className="inline-flex items-center justify-center w-6 h-6 mr-2 text-white bg-blue-300 rounded-full">
                  <span className="text-sm font-bold">✓</span>
                </span>
                Contact the customer
              </li>
              <li className="box-border relative py-1 pl-0 text-left text-slate-500 border-solid">
                <span className="inline-flex items-center justify-center w-6 h-6 mr-2 text-white bg-blue-300 rounded-full">
                  <span className="text-sm font-bold">✓</span>
                </span>
                Book and Deliver the loads
              </li>
            </ul>
          </div>

          <div
            data-aos="fade-left"
            className="box-border relative w-full max-w-md px-4 mt-10 mb-4 text-center bg-no-repeat bg-contain border-solid md:mt-0 md:max-w-none lg:mb-0 md:w-1/2"
          >
            <img
              src={truckpark}
              alt=""
              className="pl-4 sm:pr-10 xl:pl-10 lg:pr-32"
            />
          </div>
        </div>
      </section>

      <section
        id="plans"
        className="box-border py-8 leading-7 text-slate-900 bg-white border-0 border-slate-200 border-solid sm:py-12 md:py-16 lg:py-24"
      >
        <div className="box-border max-w-5xl px-4 pb-12 mx-auto border-solid sm:px-6 md:px-6 lg:px-4">
          <div className="flex flex-col items-center leading-7 text-center text-slate-900">
            <h2 className="box-border m-0 text-3xl font-semibold leading-tight tracking-tight text-black border-solid md:text-4xl">
              Pricing Options
            </h2>
            <p className="box-border mt-4 text-2xl leading-normal text-slate-900 border-solid">
              We've got a plan for companies of any size
            </p>
          </div>
          <div className="grid max-w-md mx-auto mt-6 overflow-hidden leading-7 text-slate-900 border border-b-4 border-blue-600 rounded-xl md:max-w-lg lg:max-w-none sm:mt-10 lg:grid-cols-3">
            <div
              data-aos="zoom-in-right"
              data-aos-duration="1500"
              className="box-border px-4 py-8 mb-6 text-center bg-white border-solid lg:mb-0 sm:px-4 sm:py-8 md:px-8 md:py-12 lg:px-10"
            >
              <h3 className="m-0 text-2xl font-semibold leading-tight tracking-tight text-black border-0 border-solid sm:text-3xl md:text-4xl">
                Starter Business
              </h3>
              <p className="mt-3 leading-7 text-slate-900 border-0 border-solid">
                The starter business plan is a good fit for smaller teams and
                startups
              </p>
              <div className="flex items-center justify-center mt-6 leading-7 text-slate-900 border-0 border-solid sm:mt-8">
                <p className="box-border m-0 text-6xl font-semibold leading-normal text-center border-0 border-slate-200">
                  Free
                </p>
              </div>

              <button className="inline-flex items-center justify-center w-full py-3 mt-6 font-sans text-sm leading-none text-center text-blue-600 no-underline bg-transparent border border-b-2 border-blue-600 rounded-md cursor-pointer hover:bg-blue-600 hover:border-blue-600 hover:text-white sm:text-base sm:mt-8 md:text-lg">
                Claim Now
              </button>
            </div>
            <div
              data-aos="zoom-in"
              data-aos-duration="1500"
              className="box-border px-4 py-8 mb-6 text-center bg-slate-100 border border-slate-300 border-solid lg:mb-0 sm:px-4 sm:py-8 md:px-8 md:py-12 lg:px-10"
            >
              <h3 className="m-0 text-2xl font-semibold leading-tight tracking-tight text-black border-0 border-solid sm:text-3xl md:text-4xl">
                Profesional
              </h3>
              <p className="mt-3 leading-7 text-slate-900 border-0 border-solid">
                The profesional plan is a good fit for medium-size to larger
                companies
              </p>
              <div className="flex items-center justify-center mt-6 leading-7 text-slate-900 border-0 border-solid sm:mt-8">
                <p className="box-border m-0 text-6xl font-semibold leading-normal text-center border-0 border-slate-200">
                  Free
                </p>
              </div>
              <button className="inline-flex items-center justify-center w-full py-3 mt-6 font-sans text-sm leading-none text-center text-white no-underline bg-blue-600 border-b-4 border-blue-700 rounded cursor-pointer hover:text-white sm:text-base sm:mt-8 md:text-lg">
                Claim Now
              </button>
            </div>
            <div
              data-aos="zoom-in-left"
              data-aos-duration="1500"
              className="box-border px-4 py-8 text-center bg-white border-solid sm:px-4 sm:py-8 md:px-8 md:py-12 lg:px-10"
            >
              <h3 className="m-0 text-2xl font-semibold leading-tight tracking-tight text-black border-0 border-solid sm:text-3xl md:text-4xl">
                Premium
              </h3>
              <p className="mt-3 leading-7 text-slate-900 border-0 border-solid">
                The premium plan is a good fit for larger and enterprise
                companies.
              </p>
              <div className="flex items-center justify-center mt-6 leading-7 text-slate-900 border-0 border-solid sm:mt-8">
                <p className="box-border m-0 text-6xl font-semibold leading-normal text-center border-0 border-slate-200">
                  Free
                </p>
              </div>
              <button className="inline-flex items-center justify-center w-full py-3 mt-6 font-sans text-sm leading-none text-center text-blue-600 no-underline bg-transparent border border-b-2 border-blue-600 rounded cursor-pointer hover:bg-blue-600 hover:border-blue-600 hover:text-white sm:text-base sm:mt-8 md:text-lg">
                Claim Now
              </button>
            </div>
          </div>
        </div>
      </section>

      <section id="contact" className="w-full bg-white pb-7 lg:pb-24">
        <div className="flex flex-col items-center leading-7 text-center text-slate-900">
          <h2 className="box-border m-0 text-3xl font-semibold leading-tight tracking-tight text-black border-solid md:text-4xl">
            Contact us
          </h2>
        </div>
        <div className="box-border flex flex-col items-center content-center px-8 mx-auto leading-6 text-black border-0 border-slate-300 border-solid lg:flex-row max-w-7xl lg:px-16">
          <div className="box-border relative w-full max-w-md px-4 mt-5 mb-4 -ml-5 text-center bg-no-repeat bg-contain border-solid lg:ml-0 lg:mt-0 lg:max-w-none lg:mb-0 lg:w-1/2 xl:pl-10">
            <img
              src={contact}
              alt="contact"
              className="p-2 pl-6 pr-5 xl:pl-16 xl:pr-20"
            />
          </div>

          <div className="box-border order-first w-full text-black border-solid lg:w-1/2 lg:pl-10 lg:order-none">
            <div className="max-w-screen-md mx-auto p-5">
              <div className="text-center mb-16">
                <p className="mt-4 text-sm leading-7 text-slate-500 font-regular uppercase">
                  Contact
                </p>
                <h3 className="text-3xl sm:text-4xl leading-normal font-extrabold tracking-tight text-slate-900">
                  Get In <span className="text-blue-600">Touch</span>
                </h3>
              </div>

              <form onSubmit={handleSend} ref={form} className="w-full">
                <div className="flex flex-wrap -mx-3 mb-6">
                  <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                    <label
                      className="block uppercase tracking-wide text-slate-700 text-xs font-bold mb-2"
                      htmlFor="firstname"
                    >
                      First Name
                    </label>
                    <input
                      className="appearance-none block w-full bg-slate-200 text-slate-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                      id="firstname"
                      value={firstname}
                      onChange={(e) => setFirstname(e.target.value)}
                      name="firstname"
                      type="text"
                      placeholder="Jane"
                    />
                  </div>
                  <div className="w-full md:w-1/2 px-3">
                    <label
                      className="block uppercase tracking-wide text-slate-700 text-xs font-bold mb-2"
                      htmlFor="lastname"
                    >
                      Last Name
                    </label>
                    <input
                      className="appearance-none block w-full bg-slate-200 text-slate-700 border border-slate-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-slate-500"
                      id="lastname"
                      value={lastname}
                      onChange={(e) => setLastname(e.target.value)}
                      name="lastname"
                      type="text"
                      placeholder="Doe"
                    />
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-6">
                  <div className="w-full px-3">
                    <label
                      className="block uppercase tracking-wide text-slate-700 text-xs font-bold mb-2"
                      htmlFor="email"
                    >
                      Email Address
                    </label>
                    <input
                      className="appearance-none block w-full bg-slate-200 text-slate-700 border border-slate-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-slate-500"
                      id="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      name="email"
                      type="email"
                      required
                      placeholder="********@*****.**"
                    />
                  </div>
                </div>

                <div className="flex flex-wrap -mx-3 mb-6">
                  <div className="w-full px-3">
                    <label
                      className="block uppercase tracking-wide text-slate-700 text-xs font-bold mb-2"
                      htmlFor="message"
                    >
                      Your Message
                    </label>
                    <textarea
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      required
                      name="message"
                      id="message"
                      rows="5"
                      className="appearance-none block w-full bg-slate-200 text-slate-700 border border-slate-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-slate-500"
                    ></textarea>
                  </div>
                  <div className="flex justify-between w-full px-3">
                    <button
                      className="shadow bg-blue-600 hover:bg-blue-700 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-6 rounded"
                      type="submit"
                    >
                      Send Message
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <a
        href="https://api.whatsapp.com/send?phone=263713664658"
        className="fixed w-[60px] h-[60px] p-4 right-10  bottom-10 text-center text-3xl text-white  bg-green-400  rounded-full shadow-lg hover:bg-green-600 "
      >
        <Icon
          className="text-3xl"
          size={1}
          path={mdiWhatsapp}
          rotate={90}
          horizontal
          vertical
        />
      </a>
      <footer className="footer-1 bg-slate-100 py-8 sm:py-12">
        <div className="container mx-auto px-4">
          <div
            style={{ margin: "auto" }}
            className="max-w-5xl sm:flex sm:flex-wrap sm:-mx-4 md:py-4"
          >
            <div className="px-4 sm:w-1/2 md:w-1/4">
              <h5 className="text-xl font-bold mb-6">Links</h5>
              <ul className="list-none footer-links">
                <li className="mb-2">
                  <a
                    href="#home"
                    className="nav-link border-b border-solid border-transparent hover:border-blue-600 hover:text-blue-600"
                  >
                    Home
                  </a>
                </li>
                <li className="mb-2">
                  <a
                    href="#about"
                    className="nav-link border-b border-solid border-transparent hover:border-blue-600 hover:text-blue-600"
                  >
                    About
                  </a>
                </li>
                <li className="mb-2">
                  <a
                    href="#services"
                    className="nav-link border-b border-solid border-transparent hover:border-blue-600 hover:text-blue-600"
                  >
                    Services
                  </a>
                </li>
                <li className="mb-2">
                  <a
                    href="#plans"
                    className="nav-link border-b border-solid border-transparent hover:border-blue-600 hover:text-blue-600"
                  >
                    Plans
                  </a>
                </li>
                <li className="mb-2">
                  <a
                    href="#contact"
                    className="nav-link border-b border-solid border-transparent hover:border-blue-600 hover:text-blue-600"
                  >
                    Contact
                  </a>
                </li>
                <li className="mb-2">
                  <a
                    href="https://api.whatsapp.com/send?phone=263713664658"
                    className="border-b border-solid border-transparent hover:border-blue-600 hover:text-blue-600"
                  >
                    Whatsapp
                  </a>
                </li>
              </ul>
            </div>
            <div className="px-4 sm:w-1/2 md:w-1/4 mt-8 sm:mt-0">
              <h5 className="text-xl font-bold mb-6">Product</h5>
              <ul className="list-none footer-links">
                <li className="mb-2">
                  <a
                    href="/"
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                    className="border-b border-solid border-transparent"
                  >
                    loads
                  </a>
                </li>
                <li className="mb-2">
                  <a
                    href="/"
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                    className="border-b border-solid border-transparent"
                  >
                    Trucks
                  </a>
                </li>
                <li className="mb-2">
                  <a
                    href="/"
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                    className="border-b border-solid border-transparent"
                  >
                    Jobs
                  </a>
                </li>
                <li className="mb-2">
                  <a
                    href="/"
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                    className="border-b border-solid border-transparent"
                  >
                    Equipment
                  </a>
                </li>
              </ul>
            </div>
            <div className="px-4 sm:w-1/2 md:w-1/4 mt-8 md:mt-0">
              <h5 className="text-xl font-bold mb-6">Company</h5>
              <ul className="list-none footer-links">
                <li className="mb-2">
                  <a
                    href="/"
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                    className="border-b border-solid border-transparent hover:border-blue-600 hover:text-blue-600"
                  >
                    Partners
                  </a>
                </li>
                <li className="mb-2">
                  <a
                    href="/"
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                    className="border-b border-solid border-transparent hover:border-blue-600 hover:text-blue-600"
                  >
                    Investors
                  </a>
                </li>
                <li className="mb-2">
                  <a
                    href="/"
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                    className="border-b border-solid border-transparent hover:border-blue-600 hover:text-blue-600"
                  >
                    FAQ
                  </a>
                </li>
              </ul>
            </div>
            <div className="px-4 sm:w-1/2 md:w-1/4 mt-8 md:mt-0">
              <h5 className="text-xl font-bold mb-6">Help</h5>
              <ul className="list-none footer-links">
                <li className="mb-2">
                  <a
                    href="/"
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                    className="border-b border-solid border-transparent hover:border-blue-600 hover:text-blue-600"
                  >
                    Privacy Policy
                  </a>
                </li>
                <li className="mb-2">
                  <a
                    href="#contact"
                    className="nav-link border-b border-solid border-transparent hover:border-blue-600 hover:text-blue-600"
                  >
                    Customer Support
                  </a>
                </li>
                <li className="mb-2">
                  <a
                    href="/"
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                    className="border-b border-solid border-transparent hover:border-blue-600 hover:text-blue-600"
                  >
                    Careers
                  </a>
                </li>
              </ul>
            </div>
            <div className="px-4 mt-4 sm:w-1/3 sm:mx-auto">
              <h5 className="text-xl font-bold mb-6 sm:text-center">
                Stay connected
              </h5>
              <div className="flex sm:justify-center">
                <a
                  href="/"
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                  className="w-8 h-8 border border-slate-400 rounded-full text-center text-slate-600 hover:text-white hover:bg-blue-600 hover:border-blue-600"
                >
                  <Icon className=" mr-1" size={1} path={mdiFacebook} />
                </a>
                <a
                  href="/"
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                  className="w-8 h-8 border border-slate-400 rounded-full text-center ml-2 text-slate-600 hover:text-white hover:bg-blue-400 hover:border-blue-400"
                >
                  <Icon className=" mr-1" size={1} path={mdiTwitter} />
                </a>
                <a
                  href="/"
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                  className="w-8 h-8 border border-slate-400 rounded-full text-center ml-2 text-slate-600 hover:text-white hover:bg-red-600 hover:border-red-600"
                >
                  <Icon className=" mr-1" size={1} path={mdiInstagram} />
                </a>
              </div>
            </div>
          </div>

          <div className="sm:flex sm:flex-wrap sm:-mx-4 mt-6 pt-6 sm:mt-12 sm:pt-12 border-t px-5">
            <div className="sm:w-full px-4 md:w-1/6">
              <a
                href="#_"
                className="flex items-center mb-5 font-medium text-slate-900 lg:w-auto lg:items-center lg:justify-center md:mb-0"
              >
                <img
                  className="h-12 w-36 lg:h-16 lg:w-48"
                  src={LOG3}
                  alt="logo"
                />
              </a>
            </div>
            <div className="px-4 sm:w-1/2 md:w-1/4 mt-4 md:mt-0">
              <h6 className="font-bold mb-2">Address</h6>
              <address className="not-italic mb-4 text-sm">
               31 Brickfield Woodstock
                <br />
                Cape Town, South Africa
              </address>
            </div>
            <div className="px-4 sm:w-1/2 md:w-1/4 mt-4 md:mt-0">
              <h6 className="font-bold mb-2">March Trading</h6>
              <p className="mb-4 text-sm">
                © 2019-2024 Cape 2 Cairo Loads.
                <br />
                <em>All rights reserved.</em>
              </p>
            </div>
            <div className="px-4 md:ml-auto mt-6 sm:mt-4 md:mt-0">
              <a
                href="https://app.c2cloads.com/login"
                className="flex items-center w-full px-6 py-3 mb-3 text-lg text-white bg-blue-600 rounded-md sm:mb-0 hover:bg-blue-700 sm:w-auto"
              >
                Try It Free
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-5 h-5 ml-1"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <line x1="5" y1="12" x2="19" y2="12"></line>
                  <polyline points="12 5 19 12 12 19"></polyline>
                </svg>
              </a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;
